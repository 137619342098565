import './App.css';
import React, { useEffect, useState } from 'react';

export const App = () => {
  const [beverages, setBeverages] = useState([]);

  useEffect(() => {
    console.log('Test');
    const url =
      'https://cloudworker-example.devtronaut.workers.dev/api/beverages';

    fetch(url, {
      method: 'GET',
    })
      .then((res) => {
        if (res.status != 200) {
          return;
        }

        res.json();
      })
      .then((data) => {
        console.log(data);
        setBeverages(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [setBeverages]);

  return (
    <div>
      {beverages.map((b, idx) => (
        <div key={idx}>{b.CompanyName}</div>
      ))}
    </div>
  );
};
